<template>
  <div>

    <div class="row align-items-end">
      <div class="col-3 my-3">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-4 my-3">
        <label for="itemInput">Produit</label>
         <vue-select 
          :options="filteredItems"
          @input="onItemChangeSelected(item_reference)"
          v-model="item_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
        />
      </div>

      <div class="col-5 my-3">
        <label for="itemFullName"> Désignation du produit </label>
        <input
          id="itemFullName"
          class="form-control"
          :value="selectedItem ? selectedItem.fullName : ''"
          disabled
        />
      </div>

      <div class="col-3 my-3">
        <label for="tva"> TVA </label>
        <input
          id="tva"
          class="form-control"
          :value="selectedItem ? selectedItem.tva + ' %' : ''"
          disabled
        />
      </div>

      <div class="col-3 my-3">
        <label for="measuring"> Unité </label>
        <input
          id="measuring"
          class="form-control"
          :value="selectedItem ? selectedItem.measuring : ''"
          disabled
        />
      </div>

      <div class="col-3 my-3">
        <label for=""> Stock <span class="text-danger">*</span> {{ 
          purchaseItem.stock_reference +'--'
        }}
        </label>
        <select v-model="purchaseItem.stock_reference" class="form-select" :disabled="purchaseItem.stock_reference !== ''">
          <option value="" selected>Créer un nouveau stock</option>
          <option
            v-for="(stock, index) in filteredStockItems"
            :key="index"
            :value="stock.reference"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }} {{ stock.measuring }}
          </option>
        </select>
        <!-- <input v-if="purchaseItem.stock_reference"
         type="text" class="form-control" v-model="purchaseItem.stock_reference"
          disabled> -->
      </div>

      <div class="col-3 my-3">
        <label for=""> Désignation de stock </label>
        <!-- <input
          type="text"
          v-model="purchaseItem.fullName"
          :disabled="purchaseItem.stock_reference != ''"
          class="form-control"
        /> -->

         <select
          v-model="purchaseItem.stock_reference"
          class="form-select"
          :disabled="purchaseItem.stock_reference"
        >
          <option
            v-for="(stock, index) in stocks"
            :key="index++"
            :value="stock.reference"
            :selected="true"
          >
            {{ stock.fullName }}
          </option>
        </select>
      </div>

      <div class="col-2 my-3">
        <label for="">Prix TTC</label>
        <input
          type="text"
          v-model="purchaseItem.priceTTC"
          @input="calculatePriceHT"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Prix HT</label>
        <input
          type="text"
          v-model="purchaseItem.priceHT"
          @input="calculatePriceTTC"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="quantity"> Quantité </label>
        <input
          name="quantity"
          type="text"
          v-model="purchaseItem.quantity"
          @input="calculateTotalPrice"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Total TTC</label>
        <input
          type="text"
          :value="
            (purchaseItem.priceTTC * purchaseItem.quantity) | toFixedWithSpace
          "
          class="form-control"
          disabled
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Total HT</label>
        <input
          type="text"
          :value="
            (purchaseItem.priceHT * purchaseItem.quantity) | toFixedWithSpace
          "
          class="form-control"
          disabled
        />
      </div>

      <div class="col-2 my-3">
        <label for=""> Quantité </label>
        <input
          type="text"
          v-model="purchaseItem.quantity"
          class="form-control"
        />
      </div>

      <div class="col-4 my-3">
        <label for="">Remarque </label>
        <input type="text" v-model="purchaseItem.remark" class="form-control" />
      </div>
    </div>
    <div class="row justify-content-end align-items-end">
      <div class="col-auto my-3">
        <label for=""></label>
        <button class="btn btn-success" @click="handleFormSubmit()">
          <i class="bi bi-plus-circle"></i> Insérer
        </button>
      </div>

      <div class="col-auto my-3">
        <label for=""> </label>
        <button
          class="btn btn-secondary d-flex align-items-center"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
          <span> Actualiser </span>
        </button>
      </div>

      <div class="col-auto my-3">
        <label for=""></label>
        <button class="btn btn-primary" type="button" @click="showModal = true">
          <i class="bi bi-plus-circle"></i> Créer article
        </button>
      </div>
    </div>
    <hr />

    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix</th>
          <th scope="col">Quantité</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Stock</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in purchase.purchase_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="text-info">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>
          <td class="text-warning">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
          </td>
          <td class="text-info">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }} / {{ item.stock.reference }}
            </span>
          </td>
          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
          <td class="d-flex">
            <button @click="remove(item.id)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
            <button @click="editItem(item)" class="btn text-secondary">
              <i class="bi bi-pencil-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalQuantityItems }}
            </span>
          </td>
          <td>
            <span
              v-if="totalPriceTTCItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalPriceTTCItems }}
            </span>
            DH
          </td>

          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat ( Quantité totale
            : <strong>{{ totalQuantityItems }}</strong> Kg, Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="next(purchase.reference)"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      purchaseItem: {
        priceTTC: 0,
        priceHT: 0,
        totalPriceHT: 0,
        totalPriceTTC: 0,
        quantity: 1,
      },
      section_reference: null,
      accept: false,
      item_reference: null,
      order_reference: "",
      showModal: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),

    ...mapGetters("purchase", {
      purchase: "getPurchase",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),

    ...mapGetters("stock", {
      stocks: "getStocks",
      stockItems: "getStockItems",
    }), 

    /* filteredStocks() {
      return this.stocks.filter(
        (stock) => stock.item_reference === this.purchaseItem.item_reference
      );
    }, */

    filteredStocks() {
      if (this.selectedItem)
        return this.stocks.filter(
          (stock) => stock.item_reference == this.selectedItem.reference
        );
      return null;
    },
    filteredStockItems() {
      if (this.selectedItem)
        return this.stockItems.filter(
          (stockItem) => stockItem.item_reference == this.selectedItem.reference
        );
      return null;
    },

    filteredItems() {
      if (this.section_reference == null) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
  }, 
  methods: {
    async handleFormSubmit() {
      if (this.purchaseItem.id != null) await this.update();
      else await this.save();
      await this.refresh();
    },
    async update() {
      try {
        await this.$store.dispatch("purchase/updateItem", this.purchaseItem);
        await (this.purchaseItem = {});
        await this.$store.dispatch("stock/getAll");
        await this.$store.dispatch("purchase/getAllItems");
        await this.refresh();
      } catch (error) {}
    },

    async save() {
      await (this.purchaseItem.item_reference = this.selectedItem.reference);
      await (this.purchaseItem.purchase_reference = this.purchase.reference);
      try {
        await this.$store.dispatch("purchase/storeItem", this.purchaseItem);
        await (this.purchaseItem = {
          priceTTC: 0,
          priceHT: 0,
          totalPriceHT: 0,
          totalPriceTTC: 0,
          quantity: 1,
        });
        await (this.section_reference = null);
        await (this.item_reference = null);
        await (this.selectedItem = {});
        await this.$store.dispatch("stock/getAll");
        await this.$store.dispatch("purchase/getAllItems");
        await this.refresh();
      } catch (error) {}
    },

    async remove(id) {
      await this.$store.dispatch("purchase/deleteItem", id);
    },

    async editItem(item) {
      await (this.item_reference = item.item_reference);
      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));
      console.log(this.selectedItem);
      // return

      await (this.purchaseItem.item_reference = this.selectedItem.reference);
      await (this.purchaseItem.purchase_reference = this.purchase.reference);
      await (this.purchaseItem.stock_reference = item.stock_reference);
      await (this.purchaseItem.id = item.id);
      await (this.purchaseItem.quantity = item.quantity);
      await (this.purchaseItem.priceTTC = item.priceTTC);
      await (this.purchaseItem.priceHT = item.priceHT);

      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }

      if (item.stock_reference) {
        const stock = this.filteredStocks.find(p => p.reference === item.stock_reference);
        this.purchaseItem.stock_reference = stock.reference;
      } else {
        this.purchaseItem.stock_reference = "";
      }

      await (this.section_reference = "");
    },

    async refresh() {
      await this.$store.dispatch("purchase/show", this.$route.params.reference);
      // await this.$store.dispatch("section/getAll");
      // await this.$store.dispatch("item/getAll");
      // await this.$store.dispatch("stock/getAll");
      //       await this.$store.dispatch("stock/fetchStocksItems");
      await this.$store.dispatch("stock/fetchStocksItems");
      await this.$store.dispatch("order/fetchSupplierOrders");
      await this.$store.dispatch("section/getAll");
      await this.$store.dispatch("item/getAll");
      await this.$store.dispatch("purchase/getAllItems");
      await this.$store.dispatch("stock/getAll");

    },

    async next(reference) {
      let purchase_reference = reference;
      this.$router.push({
        name: "purchases-edit",
        query: { reference: purchase_reference },
      });
    },

    async onItemChangeSelected() {
      await (this.purchaseItem.priceHT = 0.0);
      await (this.purchaseItem.priceTTC = 0.0);
      await (this.purchaseItem.quantity = 1);
      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));
      await console.log(this.item_reference);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await console.log("***", this.selectedItem);
        await (this.item_reference = "");
      }
      // // await (this.item_reference = "");
    },

    async calculatePriceHT() {
      this.purchaseItem.priceHT =
        Math.round(
          (this.purchaseItem.priceTTC / (1 + this.selectedItem.tva / 100)) * 100
        ) / 100;
    },

    async calculatePriceTTC() {
      this.purchaseItem.priceTTC =
        Math.round(
          this.purchaseItem.priceHT * (1 + this.selectedItem.tva / 100) * 100
        ) / 100;
    },

    async calculateTotalPrice() {
      await (this.purchaseItem.totalPriceTTC = Math.round(
        this.purchaseItem.priceTTC * this.purchaseItem.quantity
      ));

      await (this.purchaseItem.totalPriceHT = Math.round(
        this.purchaseItem.priceHT * this.purchaseItem.quantity
      ));
    },
  },
  async beforeMount() {
    await this.$store.dispatch("purchase/show", this.$route.params.reference);
    // await this.$store.dispatch("section/getAll");
    // await this.$store.dispatch("item/getAll");
    // await this.$store.dispatch("stock/getAll");
    // await this.$store.dispatch("stock/fetchStocksItems");
    await this.$store.dispatch("stock/fetchStocksItems");
    await this.$store.dispatch("order/fetchSupplierOrders");
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("purchase/getAllItems");
    await this.$store.dispatch("stock/getAll");
  },

  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-yyyy");
    },

    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },

  watch: {},
};
</script>
